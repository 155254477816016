import { Page } from 'codegen/schema'
import { Layout } from 'components/layout'
import { RichTextContent } from 'components/rich-text-content'
import { useLoadInitialData } from 'hooks/database/use-load-initial-data'
import { pageQuery } from 'lib/queries'
import { getClient } from 'lib/sanity.server'
import { GetStaticProps } from 'next'
import { useAuthUser } from 'next-firebase-auth'
import { groq } from 'next-sanity'

import { urlWithSiteOrigin } from '../../utils/url-with-site-origin'

export interface HomePageProps {
  data: { page: Page; query: string; queryParams: string }
}

export const Home = ({ data: { page } }: HomePageProps) => {
  const { title, metaDescription, body } = page

  useLoadInitialData()

  const authUser = useAuthUser()
  const authLoading = !authUser.clientInitialized

  return (
    <Layout
      authLoading={authLoading}
      uid={authUser.id}
      signOut={authUser.signOut}
      seoProps={{
        title,
        description: metaDescription,
        canonicalUrl: urlWithSiteOrigin(''),
        pageUrl: urlWithSiteOrigin(''),
      }}
    >
      {/* <Loader loading={!app.paletteMode}>{app.paletteMode}</Loader> */}
      <RichTextContent body={body} />
    </Layout>
  )
}

/**
 * Fetch the data from Sanity based on the slug
 */
export const getStaticProps: GetStaticProps = async ({ preview = false }) => {
  const query = groq`${pageQuery}`
  const queryParams = { slug: 'typing-practice' }
  const pageData = await getClient(preview).fetch(pageQuery, queryParams)
  const page = pageData?.[0]

  // Escape hatch, if our query failed to return page
  if (!page) return { notFound: true }

  return {
    props: {
      // Pass down the "preview mode" boolean to the client-side
      preview,
      // Pass down the initial content, and our query
      data: { page, query, queryParams },
    },
  }
}
