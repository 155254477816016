import { PortableText as PortableTextComponent } from '@portabletext/react'
import createImageUrlBuilder from '@sanity/image-url'
import { SanityImageSource } from '@sanity/image-url/lib/types/types'
import { myPortableTextComponents } from 'context-providers/PortableTextComponents'

import { sanityConfig } from './config'

export const imageBuilder = createImageUrlBuilder({
  ...sanityConfig,
  projectId: sanityConfig.projectId || '',
  dataset: sanityConfig.dataset || '',
})

export const urlForImage = (source: SanityImageSource) => imageBuilder.image(source).auto('format').fit('max')

export const PortableText: typeof PortableTextComponent = (props) => (
  <PortableTextComponent components={myPortableTextComponents} {...props} />
)
