import styled, { css } from 'styled-components'
import { pageCenter } from 'styles/page-center.styles'

export const RichTextContainer = styled.div<{ compact?: boolean }>`
  ${pageCenter};
  font-size: ${({ compact }) => (compact ? '1.125rem' : '1.25rem')};
  line-height: ${({ compact }) => (compact ? 1.5 : 2.5)};

  h2 {
    margin-top: ${({ compact }) => (compact ? '2rem' : '3rem')};
  }

  h3 {
    margin-top: ${({ compact }) => (compact ? '1.65rem' : '2.5rem')};
  }

  h4 {
    margin-top: ${({ compact }) => (compact ? '1.25rem' : '2rem')};
  }

  p {
    margin: ${({ compact }) => (compact ? '0.75rem 0' : '1rem 0')};
  }

  ${({ theme, compact }) => css`
    ${theme.breakpoints.down.md} {
      line-height: ${compact ? 1.5 : 2};
      margin-bottom: ${compact ? '1.25rem' : '2rem'};
    }
  `}

  ul {
    padding-left: ${({ compact }) => (compact ? '3rem' : '4rem')};
  }

  .v-spacer {
    display: inline-block;
    height: ${({ compact }) => (compact ? '1rem' : '1em')};
  }
`
