import { PortableText } from '@portabletext/react'
import { BlockContent, Figure } from 'codegen/schema'
import { myPortableTextComponents } from 'context-providers/PortableTextComponents'
import { urlForImage } from 'lib/sanity'
import Image from 'next/image'
import { memo } from 'react'

import { RichTextContainer } from './rich-text-content.styles'

interface RichTextContentProps {
  heroImage?: Figure | undefined
  body: BlockContent | undefined
}

export const RichTextContent = memo(function RichTextContent(props: RichTextContentProps) {
  const { heroImage, body } = props

  const srcUrl = heroImage?.image?.asset._ref
  const alt = heroImage?.alt
  const title = heroImage?.title
  const hideHeroImage = heroImage?.hide

  const width = 808
  const height = Math.floor(width * ((heroImage?.heightToWidthRatio || 33.33) / 100))

  let imageSrc
  if (!hideHeroImage && srcUrl) imageSrc = urlForImage(srcUrl).height(height).width(width).fit('crop').url() || ''

  //TODO: n: image is not cropped correctly

  return (
    <RichTextContainer>
      {imageSrc && <Image src={imageSrc} width={width} height={height} alt={alt || ''} title={title} />}

      {body && (
        <PortableText
          // blocks={body}
          value={body}
          components={myPortableTextComponents}
        />
      )}
    </RichTextContainer>
  )
})
